.team-profile-page {
  width: 100vw;
  min-height: 100vh;
  background: url("../../assets/images/landing/bg.jpeg");
  background-position: center;
  background-size: contain;
}

.header-section {
  gap: 10px;
  margin-bottom: 100px;
  margin-top: 100px;
}

.team-name {
  color: var(--Cream, #f2e4c9);
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 6.392px;
}

.team_code {
  font-size: 1.2rem;
}

.team-members-header {
  font-size: 1.2rem;
}

.member-section {
  gap: 20px;
  margin-bottom: 60px;
}
.team-member {
  text-align: center;
  width: 250px;
  height: 30px;
  flex-shrink: 10;
  border-radius: 38px;
  border: 1px solid var(--Cream, #f2e4c9);
  background: var(--Cream, #f2e4c9);
  color: black;
}

.team-leader-container {
  position: relative;
}

.team-leader {
  color: #f2d785;
  font-family: Solway;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  right: -40%;
}
.flex-container-column {
  color: #f2e4c9;
}
.leave-section {
  margin-bottom: 30px;
}
.leave-button {
  display: inline-flex;
  padding: 14.593px 58.075px;
  justify-content: center;
  align-items: center;
  gap: 8.296px;
}
.leave-team {
  width: 150px;
  height: 38px;
  color: var(--Dark-Purple, #592236);
  font-family: Solway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 50px;
  background: var(--dont-know, #f2d785);
}
.input-image {
  width: 30px;
  height: 30px;
}

.input-section {
  gap: 20px;
}

.input-container {
  gap: 10px;
}

.upload-link-image {
  cursor: pointer;
  display: none;
}

.submit-input-box {
  flex-shrink: 0;
  text-align: center;
  width: 300px;
  height: 30px;
  flex-shrink: 10;
  border-radius: 38px;
  border: 1px solid var(--Cream, #f2e4c9);
  background: var(--Cream, #f2e4c9);
  color: black;
}

.r0-link-view {
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .header-section {
    margin-bottom: 50px;
  }

  .team-name {
    font-size: 36px;
  }

  .team-member {
    width: 200px;
  }

  .leave-button {
    padding: 10px 40px;
  }

  .input-box {
    width: 200px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .header-section {
    margin-bottom: 80px;
  }

  .team-name {
    font-size: 45px;
  }

  .team-member {
    width: 220px;
  }

  .leave-button {
    padding: 12px 48px;
  }
}

@media only screen and (min-width: 1025px) {
  .header-section {
    margin-bottom: 120px;
  }

  .team-name {
    font-size: 60px;
  }

  .team-member {
    width: 250px;
  }

  .leave-button {
    padding: 16px 64px;
  }
}
