.header {
  justify-content: space-between;
  position: fixed;
  padding: 0.5rem 7%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #424242;
}

.header .navbar a {
  margin: 0 1rem;
  font-size: 1.6rem;
}

.header .navbar .navbar-item {
  margin: 0 1rem;
  font-size: 1.6rem;
  display: inline;
  cursor: pointer;
}

.header > div > .btn {
  position: static;
}

.header .icons div {
  color: #fff;
  cursor: pointer;
  font-size: 2.5rem;
  margin-left: 2rem;
}

.header .icons {
  display: none;
}

#menu-btn {
  display: none;
}

.navbar-logo {
  width: 98px;
}

.navbar-profile-icon {
  width: 45px;
  border-radius: 100%;
  cursor: pointer;
}

.btn {
  border: 0;
  background-color: #f2e4c9;
  color: #592236;
  font-weight: 600;
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 5px;
  position: absolute;
  bottom: 30%;
}

.navbar-right-container {
  gap: 20px;
}

@media (max-width: 805px) and (min-width: 536px) {
  h2 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 80px;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 400;
  }

  .header .icons {
    display: flex;
  }

  .logo {
    width: 40px;
  }

  .node {
    width: 60px;
  }
}

@media (max-width: 805px) {
  .header .logo img {
    width: 70px;
  }

  .header {
    padding: 0.7rem 2rem;
  }

  .header .icons {
    display: flex;
  }

  #menu-btn {
    display: block;
    width: 30px;
  }

  .header .navbar {
    border-radius: 24px;
    position: absolute;
    top: 100%;
    right: 100%;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 1.5rem 5%;
  }

  .header .navbar.active {
    right: 0;
  }

  .header .navbar a {
    margin: 0 1rem;
    font-size: 1.6rem;
  }

  .header .navbar .navbar-item {
    display: inline;
    margin: 0 1rem;
    font-size: 1.6rem;
  }

  .header > div > .btn {
    display: none;
  }

  .btn {
    font-size: 0.75rem;
  }

  .hero-section-button {
    font-size: 0.75rem;
    padding: 7px 13px;
    margin-bottom: -10px;
  }
}

@media (min-width: 300px) and (max-width: 535px) {
  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 40px;
  }

  h3 {
    font-size: 0.75rem;
    font-weight: 400;
  }

  .logo {
    width: 40px;
  }

  .node {
    width: 60px;
  }
}
