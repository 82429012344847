.popup-page {
  width: 100vw;
  height: 100vh;
  background: #03030310;
  position: fixed;
  top: 0px;
  z-index: 2069;
}

.popup-wrapper {
  height: 100vh;
  width: 100vw;
  margin: 0 auto;
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
}

.popup {
  width: 60%;
  height: 70%;
  background: url("../../assets/images/popup/pop.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 24px;
  position: relative;
}

.popup-cross {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1;
}

.popup-content {
  color: #000;
  text-align: center;
  font-family: Solway;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.popup-wrapper > img {
  margin: 7% 0 auto 10px;
}

.hidden {
  display: none;
}

@media screen and (max-width: 1100px) {
  .popup {
    width: 80%;
    height: 80%;
  }

  .popup-content {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  .popup {
    width: 90%;
    height: 90%;
  }

  .popup-content {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .popup-content {
    font-size: 15px;
  }

  .popup-wrapper {
    flex-direction: column-reverse;
    gap: 20px;
  }

  #cross {
    align-self: flex-end;
    margin-right: 10px;
  }
}
