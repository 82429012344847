.join-page {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/images/landing/bg.jpeg");
  background-position: center;
  background-size: contain;
}

.join-page-container {
  background: url("../../assets/images/join/join-bg.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.header-section {
  gap: 50px;
}

.join-team {
  color: var(--Cream, #f2e4c9);
  text-align: center;
  font-family: Solway;
  font-size: 79.959px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 9.595px;
}
.enter-team {
  margin-top: 100px;
  color: #fff;
  text-align: center;
  font-family: Solway;
  font-size: 30.695px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.683px;
  margin-bottom: 20px;
}
.input-box {
  width: 300px;
  height: 40px;
  text-align: center;
  flex-shrink: 0;
  border-radius: 38px;
  background: linear-gradient(
    90deg,
    rgba(161, 161, 161, 0.61) 0%,
    rgba(190, 190, 190, 0.24) 28%,
    rgba(217, 217, 217, 0.15) 53.5%,
    rgba(172, 172, 172, 0.32) 74%,
    rgba(115, 115, 115, 0.61) 100%
  );
  margin-bottom: 40px;
  text-transform: uppercase;
}
.button {
  display: inline-flex;
  padding: 15px 42px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 41px;
  background: #fff;
  color: var(--Dark-Purple, #592236);
  text-align: center;
  font-family: Solway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.456px;
}
@media screen and (max-width: 768px) {
  .header-section {
    margin-top: 50px;
  }
  .join-team {
    font-size: 40px;
    letter-spacing: 5px;
  }
  .enter-team {
    font-size: 20px;
    letter-spacing: 2px;
  }
  .input-box {
    width: 250px;
  }

  .button {
    padding: 10px 20px;
    font-size: 15px;
    letter-spacing: 2.456px;
  }
}

@media screen and (max-width: 480px) {
  .header-section {
    margin-top: 20px;
    gap: 20px;
  }
  .join-team {
    font-size: 30px;
    letter-spacing: 3px;
  }
  .enter-team {
    font-size: 16px;
    letter-spacing: 1.5px;
  }
  .input-box {
    width: 200px;
  }
  .button {
    padding: 10px 20px;
    font-size: 13px;
    letter-spacing: 2.456px;
  }
}
