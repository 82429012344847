.profile-page {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/images/landing/bg.jpeg");
  background-position: center;
  background-size: contain;
}

.profile-details-container {
  width: 70vw;
  justify-content: space-around;
}

.logosection {
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 10px;
}
.logo-img {
  width: 330px;
  flex-shrink: 0;
  border-radius: 100%;
}
.profile-details {
  right: 50%;
}
.profile-username {
  display: inline-flex;
  padding: 0px 1px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}
.text-inputuser {
  text-align: center;
  width: 450px;
  height: 30px;
  border-radius: 38px;
  background: linear-gradient(
    90deg,
    rgba(161, 161, 161, 0.61) 0%,
    rgba(190, 190, 190, 0.24) 28%,
    rgba(217, 217, 217, 0.15) 53.5%,
    rgba(172, 172, 172, 0.32) 74%,
    rgba(115, 115, 115, 0.61) 100%
  );
}
.profile-email {
  margin-top: 40px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}
.text-inputmail {
  text-align: center;
  width: 450px;
  height: 30px;
  border-radius: 38px;
  background: linear-gradient(
    90deg,
    rgba(161, 161, 161, 0.61) 0%,
    rgba(190, 190, 190, 0.24) 28%,
    rgba(217, 217, 217, 0.15) 53.5%,
    rgba(172, 172, 172, 0.32) 74%,
    rgba(115, 115, 115, 0.61) 100%
  );
  gap: 20px;
}
.profile-team-name {
  margin-top: 40px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}

.profile-details-container {
  gap: 50px;
}

h1 {
  color: var(--sand-Color, #d9a566);
  font-family: Solway;
  font-size: 20px;
  font-style: normal;
  font-weight: 250;
  line-height: normal;
  letter-spacing: 4.2px;
}
.text-inputname {
  text-align: center;
  width: 450px;
  height: 30px;
  border-radius: 38px;
  background: linear-gradient(
    90deg,
    rgba(161, 161, 161, 0.61) 0%,
    rgba(190, 190, 190, 0.24) 28%,
    rgba(217, 217, 217, 0.15) 53.5%,
    rgba(172, 172, 172, 0.32) 74%,
    rgba(115, 115, 115, 0.61) 100%
  );
  gap: 20px;
}

.profile-logout {
  margin-top: 40px;
  padding: 10px 20px;
  border-radius: 38px;
  font-size: 20px;
  background: rgb(188, 21, 21);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .profile-details-container {
    margin-top: 50px;
  }
  .logo-img {
    width: 250px;
    margin-top: 40px;
  }
  .text-inputuser,
  .text-inputmail,
  .text-inputname {
    width: 350px;
  }
}

@media screen and (max-width: 480px) {
  .profile-details-container {
    margin-top: 20px;
  }
  .logosection {
    margin-top: 30px;
    margin-left: 10px;
  }
  .logo-img {
    width: 200px;
    margin-top: 30px;
  }
  .text-inputuser,
  .text-inputmail,
  .text-inputname {
    width: 250px;
  }
}
