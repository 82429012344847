.loading-page {
  width: 100vw;
  height: 100vh;
  background: #040204;
  gap: 30px;
  flex-wrap: nowrap;
}

.loading-text {
  color: var(--Cream, #f2e4c9);
  text-align: center;
  font-family: Solway;
  font-size: 30px;
  width: 60vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.loading-img {
  width: 40vw;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .loading-text {
    font-size: 20px;
  }

  .loading-img {
    width: 60vw;
  }
}
