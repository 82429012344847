.landing-page {
  width: 100vw;
  height: fit-content;
  background: url("../../assets/images/landing/bg.jpeg");
  background-position: center;
  background-size: contain;
}

.hero {
  position: relative;
  /* margin-top: 100px; */
}

.hero .bgholder {
  height: 100%;
}
.hero .bgholder > img {
  height: 100%;
  width: 100%;
  padding-left: 10%;
  display: block;
}

.btn {
  border: 0;
  background-color: #f2e4c9;
  color: #592236;
  font-weight: 600;
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 5px;
  position: absolute;
  bottom: 30%;
  cursor: pointer;
}

.hero-section-button {
  font-size: 1.5rem;
  padding: 10px 25px;
}

.landing-page h2 {
  font-size: 4.25rem;
  font-weight: 500;
  margin-bottom: 80px;
}

.landing-page h3 {
  font-size: 2.25rem;
  font-weight: 400;
}

/* updates section  */
.updates {
  height: 80vh;
  margin-bottom: 5%;
  gap: 3rem;
}

.updates .timer {
  font-size: 3rem;
  gap: 2.5rem;
  width: 80%;
}

.timer > div {
  gap: 0.5rem;
  width: 100%;
}

.timer .hours {
  padding-right: 30%;
}

.timer .seconds {
  padding-left: 30%;
}

/* Sponsor section */
.sponsor {
  margin-bottom: 15%;
}

.sponsor > div > img {
  width: 60%;
}
/* Tracks section */
.tracks {
  height: 100vh;
  justify-content: flex-start;
  margin-bottom: 100px;
}

.tracks-wrapper {
  width: 100%;
  gap: 20px;
}
.tracks-container {
  width: 95%;
  gap: 20px;
}
.track {
  height: 225px;
  width: 25%;
  background-color: rgb(242, 215, 133, 0.9);
  border-radius: 24px;
  background: url("../../assets/images/landing/noise.png")
    rgb(242, 215, 133, 0.9) 0% 0% / 100px 100px repeat;
  background-size: cover;
  cursor: pointer;
}

.track > h3 {
  text-align: center;
  color: #592236;
  margin: 12% 0;
}
/* Rounds section starts */
.rounds-wrapper {
  height: 750px;
  width: 80vw;
  margin-bottom: 145px;
}

.rounds-wrapper > div {
  height: 100%;
  width: 48%;
}

.rounds-left {
  border-right: #ffff solid 3px;
  align-items: flex-end;
  justify-content: space-between;
}

.rounds-right {
  border-left: #ffff solid 3px;
  align-items: flex-start;
  justify-content: space-between;
}

.rounds {
  gap: 20px;
  justify-content: flex-start;
}

.rounds-left > div:nth-child(1) {
  margin: 195px -22px 0 0;
}

.rounds-left > div:nth-child(2) {
  margin: 0 -22px 60px 0;
}

.rounds-right > div:nth-child(1) {
  margin: 20px 0 0 -22px;
}

.rounds-right > div:nth-child(2) {
  margin: 0 0 250px -22px;
}

.rounds > div {
  gap: 20px;
}
/* Rounds section ends */

/* Footer Section */
.footer {
  height: 720px;
  width: 100%;
  position: relative;
  justify-content: flex-start;
}
.contact {
  gap: 30px;
}
.ieee {
  width: 100%;
}
.ieee > img {
  width: 100%;
  position: absolute;
  bottom: 0%;
  z-index: 1;
}

.hero-button-container {
  gap: 20px;
  position: absolute;
  bottom: 30%;
}

.row-button {
  position: static !important;
}

.footer-icon-link {
  cursor: pointer;
}

.contact {
  z-index: 3;
}

/* media queries  between 805-536px*/
@media (max-width: 805px) and (min-width: 536px) {
  .landing-page h2 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 80px;
  }

  .landing-page h3 {
    font-size: 1.25rem;
    font-weight: 400;
  }

  .header .icons {
    display: flex;
  }

  .updates .timer {
    font-size: 1rem;
  }
  .rounds-wrapper {
    height: 645px;
  }

  .rounds-left {
    border-width: 2px;
  }

  .rounds-right {
    border-width: 2px;
  }

  .rounds-left > div:nth-child(1) {
    margin: 195px -16px 0 0;
  }

  .rounds-left > div:nth-child(2) {
    margin: 0 -16px 42px 0;
  }
  .rounds-right > div:nth-child(1) {
    margin: 20px 0 0 -16px;
  }

  .rounds-right > div:nth-child(2) {
    margin: 0 0 227px -16px;
  }

  .rounds,
  .rounds > div {
    gap: 15px;
  }

  .logo {
    width: 40px;
  }

  .node {
    width: 60px;
  }

  .footer {
    height: 480px;
  }
  .contact {
    gap: 25px;
  }
}

@media (max-width: 805px) {
  .header .logo img {
    width: 70px;
  }

  .header {
    padding: 0.7rem 2rem;
  }

  .header .icons {
    display: flex;
  }

  #menu-btn {
    display: block;
    width: 30px;
  }

  .hero {
    margin-top: 50px;
  }

  .header .navbar {
    border-radius: 24px;
    position: absolute;
    top: 100%;
    right: 100%;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 1.5rem 5%;
  }

  .header .navbar.active {
    right: 0;
  }

  .header .navbar a {
    margin: 0 1rem;
    font-size: 1.6rem;
  }

  .header > div > .btn {
    display: none;
  }

  .btn {
    font-size: 0.75rem;
  }

  .hero-section-button {
    font-size: 0.75rem;
    padding: 7px 13px;
    margin-bottom: -10px;
  }

  .updates {
    height: 45vh;
    margin-bottom: 15%;
    gap: 3rem;
  }

  .tracks {
    height: auto;
    justify-content: flex-start;
  }

  .tracks-wrapper {
    width: 100%;
    gap: 20px;
  }
  .tracks-container {
    width: 95%;
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
  .track {
    height: 175px;
    width: 75%;
  }
}

@media (min-width: 300px) and (max-width: 535px) {
  .landing-page h2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 40px;
  }

  .landing-page h3 {
    font-size: 0.75rem;
    font-weight: 400;
  }

  .tracks {
    height: auto;
    justify-content: flex-start;
  }

  .tracks-wrapper {
    width: 100%;
    gap: 20px;
  }
  .tracks-container {
    width: 95%;
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
  .track {
    height: 130px;
    width: 60%;
  }

  .track > h3 {
    margin: 18% 0;
  }

  .updates .timer {
    font-size: 1.2rem;
  }

  .rounds-wrapper {
    height: 535px;
  }

  .rounds-left {
    border-width: 2px;
  }

  .rounds-right {
    border-width: 2px;
  }

  .rounds-left > div:nth-child(1) {
    margin: 145px -15px 0 0;
  }

  .rounds-left > div:nth-child(2) {
    margin: 0 -15px 2px 0;
  }
  .rounds-right > div:nth-child(1) {
    margin: -15px 0 0 -15px;
  }

  .rounds-right > div:nth-child(2) {
    margin: 0 0 145px -15px;
  }

  .rounds > div {
    flex-direction: column;
  }

  .rounds-left .rounds > div {
    flex-direction: column-reverse;
  }

  .logo {
    width: 40px;
  }

  .node {
    width: 60px;
  }

  .footer {
    height: 280px;
  }
  .contact {
    gap: 25px;
  }
  .contact img {
    width: 20px;
  }
}
