@font-face {
  font-family: myfont;
  src: url("./assets/fonts/against regular.ttf");
}

@font-face {
  font-family: Solway;
  src: url("./assets/fonts/Solway-Regular.ttf");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Solway", serif;
  color: #f2e4c9;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  overflow-x: hidden;
  background: black;
}

input {
  border: none;
  outline: none;
  background: none;
  color: #fff;
  font-size: 20px;
  font-family: "Solway", serif;
}

button {
  border: none;
  outline: none;
  background: none;
  color: #fff;
  font-size: 20px;
  font-family: "Solway", serif;
  cursor: pointer;
}

.flex-container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

.flex-container-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

.flex-item {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  margin: 0px;
}
